import { FC, useState } from 'react';
import { useTheme } from 'styled-components';
import { MobileArrowRight } from '@agendapro/emerald-icons';
import { useTranslation } from 'react-i18next';

import { LocationAndContactWrapper } from './AddressAndContact.styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { SimpleModal } from '@/UI/Molecules/SimpleModal';
import { AddressAndContactProps } from './AddressAndContact.types';
import { Map } from '../Map';

export const AddressAndContact: FC<AddressAndContactProps> = ({
  address,
  image,
  locationId,
  outcall,
  phone,
  times,
  whatsappUrl,
  whatsappActive,
  secondaryPhone,
}) => {
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <LocationAndContactWrapper>
      <button
        className="panel-Selector"
        type="button"
        tabIndex={0}
        onClick={() => setVisible(true)}
        onKeyDown={() => setVisible(true)}
      >
        <Paragraph>{t('ADDRESSANDCONTACT')}</Paragraph>
        <MobileArrowRight color={theme.migrateColors('icon.default', 'gray')} size={20} className="collapsedButton" />
      </button>
      <SimpleModal visible={visible} title={t('ADDRESSANDCONTACT')} onClose={() => setVisible(false)}>
        <Map
          address={address}
          image={image}
          locationId={locationId}
          outcall={outcall}
          phone={phone}
          times={times}
          whatsappUrl={whatsappUrl}
          viewType="MODAL"
          whatsappActive={whatsappActive}
          secondaryPhone={secondaryPhone}
        />
      </SimpleModal>
    </LocationAndContactWrapper>
  );
};
