import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as St from './LocationTimes.styles';
import { Paragraph } from '@/UI/Atoms/Paragraph';
import { LocationTimesProps } from './types';

export const LocationTimes: FC<LocationTimesProps> = ({ className, locationsTimes }) => {
  const { t } = useTranslation();

  return (
    <St.Wrapper className={className}>
      {locationsTimes?.map((locationTime) => (
        <St.WrapperHours
          key={locationTime.dayId}
          className={`dameTimeContainer ${!locationTime.isOpen ? 'localClosed' : ''}`}
          data-testid="dameTimeContainer"
        >
          <St.TextTime weight="light">{locationTime.longDay}</St.TextTime>
          <Paragraph weight="light">{`${locationTime.isOpen ? locationTime.time : `${t('CLOSED')}`}`}</Paragraph>
        </St.WrapperHours>
      ))}
    </St.Wrapper>
  );
};
