import { FC, useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';

import { Close, SolidStar } from '@agendapro/emerald-icons';
import { useInfiniteQuery } from 'react-query';
import { getRandomNumber, getTailwindWidth } from '@/utils';
import { Skeleton } from '@/UI';
import { useShowComponent } from '@/hooks/showComponent';
import useLockBodyScroll from '@/hooks/lockBodyScroll';

import { useLocation } from '@/services/locations';
import { useGetReviewSummary } from '@/services/bookings';
import { getReviews } from '@/services/bookings/bookings.repo';

const ReviewSkeleton: FC = () => (
  <div className="py-2 border-solid border-b border-b-gray20 pb-2">
    <div className="flex justify-between pb-1">
      <div className="w-96">
        <Skeleton className={`h-4 rounded-sm ${getTailwindWidth(getRandomNumber(3, 12))}`} />
      </div>
      <div className="flex flex-col items-end pb-2">
        <div className="w-24 flex justify-end">
          <Skeleton className={`${getTailwindWidth(getRandomNumber(6, 12))} h-4 bg-edit20 rounded-sm`} />
        </div>
        <Skeleton className="h-3 w-16 mt-1 bg-background rounded-sm" />
      </div>
    </div>
    <div className="flex flex-col pb-2 w-full">
      {[...Array(getRandomNumber(2, 7)).keys()].map((i) => (
        <Skeleton className={`h-3 mb-2 ${getTailwindWidth(getRandomNumber(5, 12))} rounded-sm`} key={i} />
      ))}
    </div>
  </div>
);
const Review: FC<{
  comment: string;
  date: string;
  name: string | null;
  rating: number;
  serviceName: string;
}> = ({ comment, date, name, rating, serviceName }) => {
  const { t } = useTranslation();

  const getTimeSince = (date) => {
    const days = dayjs().diff(date, 'day');
    const months = dayjs().diff(date, 'month');
    const years = dayjs().diff(date, 'year');

    if (years >= 1) {
      if (years === 1) {
        return t('REVIEW.YEAR_SINCE_REVIEW');
      }
      return t('REVIEW.YEARS_SINCE_REVIEW', { amount: years });
    }

    if (months >= 1) {
      if (months === 1) {
        return t('REVIEW.MONTH_SINCE_REVIEW');
      }
      return t('REVIEW.MONTHS_SINCE_REVIEW', { amount: months });
    }

    if (days === 1) {
      return t('REVIEW.DAY_SINCE_REVIEW');
    }

    if (days === 0) {
      return t('REVIEW.TODAY');
    }

    return t('REVIEW.DAYS_SINCE_REVIEW', { amount: days });
  };

  return (
    <div className="py-2 border-solid border-b border-b-gray20">
      <div className="flex justify-between pb-1">
        <p className="text-sm font-bold text-gray">{serviceName}</p>
        <div className="flex justify-center items-center">
          {[...Array(5).keys()].map((star, i) => (
            <SolidStar
              size={17}
              key={star}
              className={`${i < rating ? '[&>path]:fill-edit' : '[&>path]:fill-gray20'} mr-1`}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-between pb-2 w-full">
        {name && <p className="text-xs text-gray50">{`${t('REVIEW.BY')} ${name}`}</p>}
        <p className="text-xs text-gray50 mr-0 ml-auto">{getTimeSince(date)}</p>
      </div>
      <p className="text-sm text-gray80 whitespace-pre-wrap break-words">{`"${comment}"`}</p>
    </div>
  );
};

const ReviewsInfo: FC<{ showInXLarge?: boolean }> = ({ showInXLarge }) => {
  const reviewDialog = useShowComponent();
  const { t } = useTranslation();
  const router = useRouter();
  const { locationId } = router.query as { locationId: string };
  const { data: currentLocation } = useLocation(locationId);
  const { ref, inView } = useInView();
  const { data: reviewSummary, isLoading, isError } = useGetReviewSummary(locationId);
  const isDetailed = reviewSummary?.reviewsOption === 'details';
  const {
    data: reviewsDetail,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isSuccess,
  } = useInfiniteQuery(['projects', locationId], async ({ pageParam = 1 }) => getReviews(locationId, pageParam, 5), {
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length + 1;

      return lastPage?.reviews?.length !== 0 ? nextPage : undefined;
    },
  });

  useLockBodyScroll(reviewDialog.isShown);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  if (isLoading) {
    return <Skeleton className="w-24 rounded-sm h-4" />;
  }
  if (reviewSummary?.reviewsOption === 'disable' || reviewSummary?.totalReviews === 0 || isError) {
    return null;
  }
  return (
    <>
      <button
        type="button"
        className={`flex items-center pt-2 ${showInXLarge ? 'xl:flex hidden' : 'xl:hidden'} ${
          isDetailed ? '' : 'cursor-auto'
        }`}
        onClick={isDetailed ? () => reviewDialog.show() : undefined}
      >
        <SolidStar size={20} className="[&>path]:fill-edit" />
        <p className="ml-1">
          <b>{reviewSummary?.average} </b>
          <span className="text-gray80 text-xs">
            (
            <span className={`${isDetailed ? 'underline' : ''}`}>
              {isDetailed
                ? t('REVIEW.TOTAL_SEE_REVIEWS', { amount: reviewSummary?.totalReviews })
                : t('REVIEW.TOTAL_REVIEWS', { amount: reviewSummary?.totalReviews })}
            </span>
            )
          </span>
        </p>
      </button>
      {reviewDialog.isShown && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray30 z-40 flex justify-center mt-0 items-center">
          <div className="bg-white rounded-sm xl:w-1/3 xl:h-2/3 w-full h-full pl-4 pr-3 pt-4 xl:pl-8 flex flex-col">
            <div className="flex justify-between pb-4 md:pr-5">
              <p className="text-lg font-bold">{t('REVIEW.MODAL_TITLE')}</p>
              <button type="button" onClick={() => reviewDialog.hide()}>
                <Close size={25} />
              </button>
            </div>
            <div className="overflow-y-auto h-full md:pr-5 no-scrollbar xl:show-scrollbar">
              <p className="text-base font-medium pb-3">{t('REVIEW.MODAL_DESCRIPTION')}</p>
              <div>
                {isSuccess &&
                  reviewsDetail.pages.flatMap((page) =>
                    page.reviews.map((review) => (
                      <Review
                        key={review.id}
                        comment={review.comment}
                        rating={review.stars}
                        name={review.reviewerName}
                        date={review.reviewDate}
                        serviceName={review.serviceName}
                      />
                    )),
                  )}
                <div className="w-full h-10" ref={ref} />
                {(isFetching || isFetchingNextPage) &&
                  [...Array(5).keys()].map((item) => <ReviewSkeleton key={item} />)}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsInfo;
