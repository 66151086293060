/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

import { devices } from '@/UI/Styles/Queries';
import { MapTypes } from './Map.types';
import { Paragraph } from '@/UI';

export const Wrapper = styled.div<Pick<MapTypes, 'hasDescription' | 'hasBanner' | 'viewType'>>`
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #fff;
  align-self: flex-start;
  .icon {
    top: 5px;
    position: relative;
  }
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .showTimes,
  .whatsappLink {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  ${({ hasDescription, viewType, hasBanner }) =>
    !hasDescription &&
    !hasBanner &&
    viewType !== 'MODAL' &&
    css`
      display: flex;
      width: 100%;
      max-width: 700px;
    `}
`;

export const WrapperTime = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: baseline;

  .TimeContainer {
    padding-top: 5px;
    margin-bottom: 15px;
  }
`;

export const WrapperContent = styled.div<Pick<MapTypes, 'hasDescription' | 'viewType' | 'hasBanner'>>`
  box-sizing: border-box;
  padding: 15px;
  flex-shrink: 0;
  width: 350px;
  box-sizing: border-box;

  ${({ hasDescription, viewType, hasBanner }) =>
    !hasDescription &&
    !hasBanner &&
    viewType !== 'MODAL' &&
    css`
      max-width: 50%;
    `}

  ${({ viewType }) =>
    viewType === 'MODAL' &&
    css`
      max-width: 100%;
    `}

  p {
    color: ${({ theme }) => theme.palette.grays80};
  }
  > p {
    color: ${({ theme }) => theme.palette.grays80};
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: baseline;

    &:hover {
      text-decoration: underline;
    }

    .icon {
    }
  }
`;

export const ItemDetailContainer = styled.div`
  align-items: flex-start;
  column-gap: 10px;
  display: flex;
  margin-bottom: 15px;
`;

export const TextBox = styled.div`
  width: calc(100% - 20px);

  a {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const WrapperImage = styled.div<
  Pick<MapTypes, 'hasDescription' | 'viewType' | 'hasBanner' | 'outcall' | 'collapsed' | 'image'>
>`
  display: ${({ outcall }) => (outcall ? 'none' : 'block')};
  min-height: ${({ collapsed }) => (collapsed ? '0px' : '300px')};
  background-position: center;
  background-size: cover;
  background-image: url(${({ image }) => image});
  flex-shrink: 0;
  box-sizing: border-box;
  width: 350px;
  overflow: hidden;
  transition: height 0.5s;

  @media ${devices.Medium} {
    height: ${({ collapsed, hasDescription }) => (collapsed ? '0px' : hasDescription ? '175px' : '220px')};
    min-height: ${({ collapsed }) => (collapsed ? '0px' : '220px')};
  }

  ${({ hasDescription, viewType, hasBanner }) =>
    !hasDescription &&
    !hasBanner &&
    viewType !== 'MODAL' &&
    css`
      max-width: 50%;
      display: block;
      height: 216px;
    `}

  ${({ viewType }) =>
    viewType === 'MODAL' &&
    css`
      width: 100%;
    `}
`;

export const StyledPhone = styled(Paragraph)`
  position: relative;
  top: 5px;
`;
