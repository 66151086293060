import styled, { css } from 'styled-components';

import { devices } from '@/UI/Styles/Queries';

interface CompanyInfoProps {
  collapsed?: boolean;
  hasBanner: boolean;
}

export const CompanyInfoContainer = styled.div<CompanyInfoProps>`
  display: flex;
  flex-direction: column;
  width: ${({ hasBanner }) => (hasBanner ? '100%' : 'auto')};
  box-sizing: border-box;

  @media ${devices.XLarge} {
    ${({ hasBanner }) =>
      hasBanner &&
      css`
        width: calc(100% - 350px);
      `}
    background-color: ${({ theme }) => theme.palette.backgroundSecondary};
  }

  .detailContainer {
    > p {
      text-align: justify;
    }
  }
`;

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const SocialIconsWrapper = styled.div<CompanyInfoProps>`
  transition: all 0.35s;
  margin: 14px auto 24px;

  ${({ hasBanner }) =>
    hasBanner &&
    css`
      margin-top: 40px;
      margin-left: 0;
    `}

  @media ${devices.XLarge} {
    ${({ hasBanner, collapsed }) =>
      hasBanner &&
      collapsed &&
      css`
        margin-top: 0px;
        position: absolute;
        right: 20px;
        top: 14px;
      `}

    ${({ hasBanner, collapsed }) =>
      hasBanner &&
      !collapsed &&
      css`
        display: flex;
        justify-content: end;
        position: relative;
        margin: 16px 0 20px;
      `}

    ${({ hasBanner }) =>
      !hasBanner &&
      css`
        display: flex;
        justify-content: start;
        position: relative;
        margin: 16px 0 20px 32px;
      `}
  }
`;
