import styled from 'styled-components';

import { Paragraph } from '@/UI/Atoms/Paragraph';

export const Wrapper = styled.div``;
export const WrapperHours = styled.div`
  &.dameTimeContainer {
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
  }
  &.localClosed p {
    color: ${({ theme }) => theme.palette.grays50};
  }
`;
export const TextTime = styled(Paragraph)`
  margin-bottom: 8px;
`;
