import styled from 'styled-components';

export const LocationAndContactWrapper = styled.div`
  background-color: ${({ theme }) => theme.migrateColors('background.primary', 'white')};
  padding: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.migrateColors('text.btnSecondaryDisabled', 'background')};
  border: 1px solid ${({ theme }) => theme.migrateColors('border.primary', 'background')};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  > .panel-Selector {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;
