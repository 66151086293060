import { FC } from 'react';

import { BannerLogo, ViewMore } from '@/UI';
import { CompanyInfoProps } from './types';
import { SocialIcons } from '@/UI/Molecules/SocialIcons';
import ReviewsInfo from './ReviewsInfo';
import * as St from './CompanyInfo.styles';

export const CompanyInfo: FC<CompanyInfoProps> = ({
  logo,
  banner,
  collapsed,
  rrss,
  companyDescription = '',
  locationDescription = '',
  isLoading,
}) => {
  const hasSocialMedia = rrss?.reduce((acc, current): number => {
    if (current.webAddress.trim() !== '') {
      const newValue = acc + 1;

      return newValue;
    }
    return acc;
  }, 0);

  return (
    <St.CompanyInfoContainer collapsed={collapsed} hasBanner>
      <St.HeroWrapper>
        <BannerLogo banner={banner} logo={logo} />
        {rrss && hasSocialMedia! > 0 && (
          <St.SocialIconsWrapper collapsed={collapsed} hasBanner>
            <SocialIcons rrss={rrss} className="socialIcons" isLoading={isLoading} />
          </St.SocialIconsWrapper>
        )}
      </St.HeroWrapper>
      <ViewMore
        textContent={companyDescription}
        textContentSecondary={locationDescription}
        showIcon
        maxNumOfLines={8}
        arial-label="companyDescription"
        className="companyDescription"
        data-testid="companyDescription"
        isLoading={isLoading}
      />
      <ReviewsInfo />
    </St.CompanyInfoContainer>
  );
};
